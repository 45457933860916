import React from 'react';
import './ParticipationNotFound.css';

class ParticipationNotFound extends React.Component {

    render() {
        return (
            <div className={"col-md-8 offset-md-2"}>
                <div className={"card"}>
                    <div className="card-header">
                        <h1>Participation not found</h1>
                    </div>
                    <div>
                        <p>The participation could not be found.</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default ParticipationNotFound;
