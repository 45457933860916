import React, { Component } from 'react';
import Stepper from 'react-stepper-horizontal';
import {API_URL} from "../../constants/urls";


class ProgressBar extends Component {
    constructor(props){

        super(props);

        this.state = {
            active_step: this.props.active_step,
            level: this.props.level,
            steps: [],
            previous_steps: 0      // Only used in phase 2
        };

    };


    componentDidMount() {
        var steps = [];

        var API_steps = API_URL + 'survey/' + window.location.pathname.split('/')[1] + '/get-steps/' + this.state.level;

        if(this.state.level !== undefined) {
            fetch(API_steps)
                .then(response => response.text())
                .then(data => {
                    let i;
                    for (i = 0; i < parseInt(data); i++) {
                        steps.push({});
                    }

                    this.setState({steps: steps});

                    // Used for second step, to subtract the number of steps from phase 1 from the current step number
                    if(this.state.level === 2) {
                        var API_previous_steps = API_URL + 'survey/' + window.location.pathname.split('/')[1] + '/get-steps/' + 1;


                        fetch(API_previous_steps)
                            .then(response => response.text())
                            .then(data => {
                                this.setState({active_step: this.state.active_step - parseInt(data)});
                            })
                    }

                });

        }
        else {
            this.setState({steps: [{},{},{},{},{}] })
        }

    }


    render() {
        return (
            <div className="col-md-8 offset-md-2">
                <div className={"progressbar-container"}>
                    <Stepper steps              =   { this.state.steps  }
                             activeStep         =   { this.state.active_step }
                             size               =   {20}
                             circleFontSize     =   {10}
                             activeColor        =   { '#1f8cf2' }
                             completeColor      =   { '#64aff5' }
                             completeBarColor   =   { '#64aff5' }

                    />
                </div>
                <div className={"settings"}>
                    <div>
                        <i className="fa" />
                    </div>
                </div>
            </div>

        );
    }
}

export default ProgressBar;
