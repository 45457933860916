import React from 'react';
import './Completed.css';

class Completed extends React.Component {

    render() {
        return (
            <div className={"col-md-8 offset-md-2"}>
                <div className={"card"}>
                    <div className="card-header">
                        <h1>Survey completed</h1>
                    </div>
                    <div>
                        <p>Your survey entry is already saved. Thank you.</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default Completed;
