import React from 'react';
import {API_URL, API_INFO, BASE_URL} from "../../constants/urls";
import Popup from "reactjs-popup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import './Introduction.css';

class Introduction extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            survey_id: this.props.survey_id,
            participation_id: this.props.participation_id,
            question_id: this.props.data.step_number,
            title: this.props.data.title,
            topic_description: this.props.data.text,
            show_info: this.props.data.show_info,
            info_description: '',
            info_title: '',
            backstep_show: false,
            backstep_title: '',
            backstep_description: '',
        }

        fetch(API_INFO)
            .then(response => response.json())
            .then(data => {
                this.setState({
                    info_title: data.technology,
                    info_description: data.description,
                    backstep_show: data.backstep_show,
                    backstep_description: data.backstep_description,
                    backstep_title: data.backstep_title,
                });
            });
    };

    onClickFunction(state) {
        fetch(API_URL + 'answers', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "participation": state.participation_id,
                "question_id": state.question_id,
                "survey": state.survey_id,
                "answer": {
                    "text": "read"
                }
            })
        }).then(() => this.props.componentDidMount());

    }

    onDeleteFunction(state) {
        fetch(API_URL + 'delete-answer', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "participation": state.participation_id,
                "question_id": state.question_id,
                "survey": state.survey_id,
            })
        }).then(() => this.props.componentDidMount());

    }


    render() {

        return (
            <div className={"col-md-8 offset-md-2"}>
                <div className={"card"}>
                    <div className="card-header">
                        <h1 dangerouslySetInnerHTML={{__html: this.state.title}}/>
                    </div>
                    <div>
                        <p className={"description"} dangerouslySetInnerHTML={{__html: this.state.topic_description.replaceAll('/static/survey/', BASE_URL + '/static/survey/')}}/>
                        {this.state.show_info ?
                            <Popup trigger={
                                <button className={"modal-trigger glyphicon glyphicon-info-sign"}>
                                    {this.state.info_title} <FontAwesomeIcon icon={faInfoCircle}/>
                                </button>} modal>

                                {close => (
                                    <div className={"modal"}>
                                        <div className={"modal-header-bar"}>
                                            <button className="close" onClick={close}>
                                                &times;
                                            </button>
                                            <h2 className={"modal-title"}
                                                dangerouslySetInnerHTML={{__html: this.state.info_title}}/>
                                        </div>
                                        <div className={"modal-content"}>
                                            <div dangerouslySetInnerHTML={{
                                                __html: this.state.info_description
                                                    .replaceAll('/static/survey/', BASE_URL + '/static/survey/')
                                            }}/>
                                        </div>
                                    </div>
                                )}
                            </Popup> : ''
                        }
                    </div>
                </div>
                <div className={`${this.state.backstep_show ? 'button-card' : ''} card`}>
                    {this.state.backstep_show && (this.state.question_id !== 1 && this.state.question_id !== 9) ?
                        <Popup trigger={
                            <button type="submit" className="button-faded col-lg-3 btn button-primary submit button-wide">
                                Back
                            </button>} modal>

                            {close => (
                                <div className={"modal"}>
                                    <div className={"modal-header-bar"}>
                                        <button className="close" onClick={close}>
                                            &times;
                                        </button>
                                        <h2 className={"modal-title"}
                                            dangerouslySetInnerHTML={{__html: this.state.backstep_title}}/>
                                    </div>
                                    <div className={"modal-content"}>
                                        <div dangerouslySetInnerHTML={{__html: this.state.backstep_description}}/>
                                    </div>
                                    <button type="submit" className="btn button-primary submit button-wide"
                                            onClick={() => {
                                                this.onDeleteFunction(this.state);
                                            }}>
                                        Confirm
                                    </button>
                                </div>
                            )}
                        </Popup> : ''
                    }

                    <button type="submit"
                            className={`btn button-primary ${this.state.backstep_show && (this.state.question_id !== 1 && this.state.question_id !== 9) ? 'col-lg-8' : 'button-wide'} submit`}
                            onClick={() => {
                                this.onClickFunction(this.state);
                            }}>Next
                    </button>
                </div>
                <div className={"final-space"}/>

            </div>
        );
    }
}

export default Introduction;
