import React, { Component } from 'react';

class Contacts extends Component {
  render() {
    return (
      <div>
        <h1>Contact Page</h1>
        <h2><a href="lota.uzh@gmail.com">lota.uzh@gmail.com</a></h2>
      </div>
    );
  }
}

export default Contacts;