import React, {Component} from 'react'
import {API_URL, API_INFO, BASE_URL} from "../../constants/urls";
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import Popup from "reactjs-popup";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons'


const getItemsFromArray = (sourcearray = []) =>
    Array.from({length: sourcearray.length}, (v, k) => ({
        title: sourcearray[k].title,
        description: sourcearray[k].description,
        long_description: sourcearray[k].long_description,
        id: sourcearray[k].id,
        collection: sourcearray[k].collection,
        points: sourcearray[k].points
    }));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;
    return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    // background: isDragging ? 'lightgreen' : 'grey',

    // styles we need to apply on draggables
    ...draggableStyle
});

const getItemClass = (isDragging, draggableStyle) => ({
    class: isDragging ? "draggable-card dragging-card" : "draggable-card"
});


const getColor = (input_type, microstep_nr) => {
    if (input_type === 'MI') {
        if (microstep_nr % 2 === 0) {
            return 'rgba(42,134,102,0.4)';
        } else {
            return 'rgba(181,6,27,0.4)';
        }
    } else {
        if (input_type === 'OP') {
            return 'rgba(42,134,102,0.4)'
        } else if (input_type === 'RI') {
            return 'rgba(181,6,27,0.4)'
        } else {
            return 'white'
        }
    }
};

const getText = (text, microstep_nr) => {
    let col = microstep_nr % 2 === 0 ? 'rgba(42,134,102)' : 'rgba(181,6,27)';
    if (text === '{{OR_SI}}') {
        let text = microstep_nr % 2 === 0 ? 'opportunity' : 'risk'
        return '<span style=color:' + col + '><b>' + text + '</b></span>';
    }
    if (text === '{{OR_PL}}') {
        let text = microstep_nr % 2 === 0 ? 'opportunities' : 'risks'
        return '<span style=color:' + col + '><b>' + text + '</b></span>';
    }
};

const getListStyle = (isDraggingOver, side, input_type, microstep_nr) => ({
    background: isDraggingOver && side === 'right' ? getColor(input_type, microstep_nr) : 'white',
    padding: grid,
    minHeight: 400
});

let arr_source = [];
let arr_long = [];
let arr_answer = [];
let arr_selected_goals = [];
let arr_unified_impacts = [];
let arr_unified_source = [];


class ImpactMatrix extends Component {

    constructor(props) {
        super(props);

        // push all goals to an array
        arr_selected_goals = []; // clear the previous set
        Object.keys(this.props.data.selected_goals).forEach((key) => {
            arr_selected_goals.push(this.props.data.selected_goals[key]);
        });

        // push to an array all unified impacts
        arr_unified_impacts = [];
        Object.keys(this.props.data.unified_impacts).forEach((key) => {
            arr_unified_impacts.push(this.props.data.unified_impacts[key]);
            // if the status is mixed we need to double it
            if (this.props.data.input_type === 'MI') {
                arr_unified_impacts.push(this.props.data.unified_impacts[key]);
            }

        });


        // set datastructure (sorting topics), same as arr_selected_goals but with points
        arr_source = [];
        for (let step = 0; step < arr_selected_goals.length; step++) {
            let temp = {
                id: arr_selected_goals[step].id,
                collection: arr_selected_goals[step].collection,
                title: arr_selected_goals[step].title,
                description: arr_selected_goals[step].description,
                long_description: arr_selected_goals[step].long_description,
                points: 0,
            };
            arr_source.push(temp);
        }

        // copy of arr_unified_impacts
        arr_unified_source = []
        for (let step = 0; step < arr_unified_impacts.length; step++) {
            let temp = {
                id: arr_unified_impacts[step].id,
                title: arr_unified_impacts[step].title,
            };
            arr_unified_source.push(temp);
        }

        // set long datastructure (sorting topics) without the current one
        // might delete that one later
        arr_long = [];
        for (let i in arr_unified_source) { // dimension is now unified source not arr source anymore
            let temp = [];
            for (let j in arr_source) {
                //if (arr_source[i] !== arr_source[j]) { // removed because own selection not needed anymore
                temp.push(arr_source[j]);
                //}
            }
            arr_long.push(temp)
        }

        // create an empty array with the same size
        // changed to new array
        arr_answer = [];
        for (let i in arr_unified_source) {
            let temp = [];
            arr_answer.push(temp)
        }

        this.updateMicroStepState = this.updateMicroStepState.bind(this);
        this.updateAnswerArr2 = this.updateAnswerArr2.bind(this);

        this.state = {
            page_title: this.props.data.title,
            page_description: this.props.data.text,
            survey_id: this.props.survey_id,
            participation_id: this.props.participation_id,
            question_id: this.props.data.step_number,
            question_input_type: this.props.data.input_type,
            data: this.props.data,
            microstep: 0,
            topic_arr: arr_source,  // all topics
            selection_arr: arr_long, // all others not the one selected
            answer_arr: arr_answer, // empty array
            unified_source_arr: arr_unified_source, // new array for unified
            end_reached: false,
            show_info: this.props.data.show_info,
            info_description: '',
            info_title: ''
        }

        fetch(API_INFO)
            .then(response => response.json())
            .then(data => {
                this.setState({info_title: data.technology, info_description: data.description});
            });

    }

    updateMicroStepState(stepNr) {
        this.setState({microstep: stepNr});
    }

    updateAnswerArr2(item) {
        this.setState({answer_arr: item});
    }


    render() {
        if (this.state.microstep === arr_unified_source.length) { // changed to unified
            this.setState({end_reached: true})
        }


        if (this.end_reached) {
        } else {
            return (
                <div className={"col-md-8 offset-md-2"}>
                    <div className={"card"}>
                        <div className="card-header">
                            <h1 dangerouslySetInnerHTML={{
                                __html: this.state.page_title
                                    .replaceAll('{{OR_SI}}', getText('{{OR_SI}}', this.state.microstep))
                                    .replaceAll('{{OR_PL}}', getText('{{OR_PL}}', this.state.microstep))
                                    .replaceAll('{{IMPACT}}', this.state.unified_source_arr[this.state.microstep].title)
                            }}/>
                        </div>
                        <div>
                            <p dangerouslySetInnerHTML={{
                                __html: this.state.page_description
                                    .replaceAll('{{OR_SI}}', getText('{{OR_SI}}', this.state.microstep))
                                    .replaceAll('{{OR_PL}}', getText('{{OR_PL}}', this.state.microstep))
                                    .replaceAll('{{IMPACT}}', this.state.unified_source_arr[this.state.microstep].title)
                            }}/>
                            {this.state.show_info ?
                                <Popup trigger={
                                    <button className={"modal-trigger glyphicon glyphicon-info-sign"}>
                                        {this.state.info_title} <FontAwesomeIcon icon={faInfoCircle}/>
                                    </button>} modal>

                                    {close => (
                                        <div className={"modal"}>
                                            <div className={"modal-header-bar"}>
                                                <button className="close" onClick={close}>
                                                    &times;
                                                </button>
                                                <h2 className={"modal-title"}
                                                    dangerouslySetInnerHTML={{__html: this.state.info_title}}/>
                                            </div>
                                            <div className={"modal-content"}>
                                                <div dangerouslySetInnerHTML={{
                                                    __html: this.state.info_description
                                                        .replaceAll('/static/survey/', BASE_URL + '/static/survey/')
                                                }}/>
                                            </div>
                                        </div>
                                    )}
                                </Popup> : ''
                            }
                        </div>
                    </div>

                    <ul className="card-list">
                        <MicroStep newData={this.state}
                                   topic={this.state.topic_arr[this.state.microstep]}
                                   impact={this.state.unified_source_arr[this.state.microstep]}
                                   left_column={this.props.data.headline_no_impact}
                                   right_column={this.props.data.headline_impact}
                                   input_type={this.props.data.input_type}
                                   items={this.state.selection_arr[this.state.microstep]}
                                   microstep={this.state.microstep}
                                   end_reached={this.state.end_reached}
                                   answer_arr={this.state.answer_arr}
                                   updateMicroStepState={this.updateMicroStepState}
                                   updateAnswerArr2={this.updateAnswerArr2}
                                   componentDidMount={this.props.componentDidMount}
                        />
                    </ul>
                </div>
            );
        }
    }
}

class MicroStep extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            topic_title: this.props.impact.title,
            //topic_description: this.props.topic.description,
            //topic_long_description: this.props.topic.long_description,
            left_column: this.props.left_column,
            right_column: this.props.right_column,
            input_type: this.props.input_type,
            microstep: this.props.microstep,
            items: getItemsFromArray(this.props.items),
            selected: getItemsFromArray(),
            end_reached: this.props.end_reached,
            answer_arr: this.props.answer_arr,

            participation_id: this.props.newData.participation_id,
            question_id: this.props.newData.question_id,
            survey_id: this.props.newData.survey_id
        };
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.microstep !== nextProps.microstep) {
            return {
                topic_title: nextProps.impact.title,
                //topic_description: nextProps.topic.description,
                left_column: nextProps.left_column,
                right_column: nextProps.right_column,
                input_type: nextProps.input_type,
                microstep: nextProps.microstep,
                items: getItemsFromArray(nextProps.items),
                selected: getItemsFromArray(),
            }
        }
        return nextProps.state === prevState.state
            ? {}
            : {state: nextProps.state}
    }

    id2List = {
        droppable: 'items',
        droppable2: 'selected'
    };

    getList = title => this.state[this.id2List[title]];

    onDragEnd = result => {
        const {source, destination} = result;

        // dropped outside the list
        if (!destination) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            const items = reorder(
                this.getList(source.droppableId),
                source.index,
                destination.index
            );

            let state = {items};

            if (source.droppableId === 'droppable2') {
                state = {selected: items};
            }

            this.setState(state);
        } else {
            const result = move(
                this.getList(source.droppableId),
                this.getList(destination.droppableId),
                source,
                destination
            );

            this.setState({
                items: result.droppable,
                selected: result.droppable2
            });
        }
    };

    componentDidMount() {

        this.setState({
            isMobile: window.innerWidth < 768
        });

        window.addEventListener('resize', () => {
            this.setState({
                isMobile: window.innerWidth < 768
            });
        }, false);
    }

    sendAnswerBack(state) {
        fetch(API_URL + 'answers', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "participation": state.participation_id,
                "question_id": state.question_id,
                "survey": state.survey_id,
                "answer": state.answer_arr,
            })
        })
            .then(() => this.props.componentDidMount());
    }

    updateAnswerArr(title, index, item, type) {
        item.forEach((value, key) => {
            value.points = 1 / (key + 1);
        });
        this.state.answer_arr[index] = [title, item, type];
    }


    // Normally you would want to split things out into separate components.
    // But in this example everything is just done in one place for simplicity
    render() {

        const left_card_col = this.state.isMobile ? 'col-7 column-left mobile' : 'col-6 column-left';
        const right_card_col = this.state.isMobile ? 'col-5 column-right mobile' : 'col-6 column-right';
        if (this.props.end_reached) {
            this.sendAnswerBack(this.state);
        } else {
            return (
                <div>
                    <h5>Impact:</h5>
                    <div className={"card"}>
                        <div className="card-header">
                            <h1 dangerouslySetInnerHTML={{__html: this.state.topic_title + " ..."}}/>
                        </div>
                        {/*<div>
                            <p dangerouslySetInnerHTML={{__html: this.state.topic_description}}/>
                        </div> */}
                    </div>


                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <div className={"row drag-drop-container"}>

                            <div className={left_card_col}>

                                <h5 dangerouslySetInnerHTML={{__html: "... " + this.state.left_column.replaceAll('{{OR_SI}}', getText('{{OR_SI}}', this.state.microstep)).replaceAll('{{OR_PL}}', getText('{{OR_PL}}', this.state.microstep))}}></h5>
                                <Droppable droppableId="droppable">
                                    {(provided, snapshot) => (
                                        <div className={"card card-side left-card"}
                                             ref={provided.innerRef}
                                             style={getListStyle(snapshot.isDraggingOver, 'left', this.state.input_type, this.state.microstep)}>
                                            {this.state.items.map((item, index) => (
                                                <Draggable
                                                    key={item.title}
                                                    draggableId={item.title}
                                                    index={index}>
                                                    {(provided, snapshot) => (
                                                        <div className={getItemClass(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style).class}
                                                             ref={provided.innerRef}
                                                             {...provided.draggableProps}
                                                             {...provided.dragHandleProps}
                                                             style={getItemStyle(
                                                                 snapshot.isDragging,
                                                                 provided.draggableProps.style
                                                             )}>
                                                            <div className={"nonselect"}>


                                                                <Popup trigger={

                                                                    <a className={"modal-trigger glyphicon glyphicon-info-sign"}>
                                                                        <FontAwesomeIcon icon={faInfoCircle}/>
                                                                    </a>} modal>

                                                                    {close => (
                                                                        <div className={"modal"}>
                                                                            <div className={"modal-header-bar"}>
                                                                                <a className="close"
                                                                                   onClick={close}>
                                                                                    &times;
                                                                                </a>
                                                                                <h2 className={"modal-title"}>{item.title}</h2>
                                                                            </div>
                                                                            <div className={"long_description"}
                                                                                 dangerouslySetInnerHTML={{__html: item.long_description}}/>
                                                                        </div>
                                                                    )}
                                                                </Popup>
                                                                <h4 className={"modal-title"}>{item.title}</h4>
                                                                <p className={"nonselect"}>{item.description}</p>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>

                            </div>

                            <div className={right_card_col}>
                                <h5 dangerouslySetInnerHTML={{__html: "... " + this.state.right_column.replaceAll('{{OR_SI}}', getText('{{OR_SI}}', this.state.microstep)).replaceAll('{{OR_PL}}', getText('{{OR_PL}}', this.state.microstep))}}></h5>
                                <Droppable droppableId="droppable2">
                                    {(provided, snapshot) => (
                                        <div className={"card card-side right-card"}
                                             ref={provided.innerRef}
                                             style={getListStyle(snapshot.isDraggingOver, 'right', this.state.input_type, this.state.microstep)}>
                                            {this.state.selected.map((item, index) => (
                                                <Draggable
                                                    key={item.title}
                                                    draggableId={item.title}
                                                    index={index}>
                                                    {(provided, snapshot) => (
                                                        <div className={getItemClass(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style).class}
                                                             ref={provided.innerRef}
                                                             {...provided.draggableProps}
                                                             {...provided.dragHandleProps}
                                                             style={getItemStyle(
                                                                 snapshot.isDragging,
                                                                 provided.draggableProps.style
                                                             )}>
                                                            <div>
                                                                <h4>{item.title}</h4>
                                                                <p className={"item-content"}>{item.description}</p>
                                                            </div>

                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>

                            </div>


                        </div>

                    </DragDropContext>

                    <div className={"card"}>
                        <button type="submit"
                                className="btn button-primary button-wide submit"
                                onClick={() => {
                                    if (this.state.microstep + 1 < arr_unified_impacts.length) {
                                        this.updateAnswerArr(this.props.impact.title, this.props.microstep, this.state.selected, this.state.input_type);
                                        this.props.updateAnswerArr2(this.state.answer_arr);
                                        this.props.updateMicroStepState(this.props.microstep + 1)
                                    } else {
                                        this.updateAnswerArr(this.props.impact.title, this.props.microstep, this.state.selected, this.state.input_type);
                                        this.sendAnswerBack(this.state)
                                    }
                                }}>
                            Forward
                        </button>
                        {/*<button type="submit"   className="btn login-button button-primary button-wide submit" onClick={() =>{this.props.updateMicroStepState(this.props.microstep-1)}}> Back </button>*/}

                    </div>

                    <div className={"final-space"}/>

                </div>

            );
        }
    }
}

export default ImpactMatrix;

