import React from 'react';

import {createBrowserHistory} from "history";
import {API_URL} from "../../constants/urls";

const customHistory = createBrowserHistory();

class Registration extends React.Component {

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            survey_id: this.props.survey_id,
            formControls: {
                email: {value: ''},
                first_name: {value: ''},
                last_name: {value: ''}
            },
            participation: "",
            button_disables: true
        };
    }

    handleSubmit(event) {
        event.preventDefault();

        fetch(API_URL + 'survey/' + this.state.survey_id + '/create-participation/', {
            method: 'POST',
            body: JSON.stringify({
                "email": this.state.formControls.email.value,
                "first_name": this.state.formControls.first_name.value,
                "last_name": this.state.formControls.last_name.value
            })
        })
            .then(res => res.text())
            .then(response => this.setState({participation: response}))
            .then(() =>
                customHistory.push({
                    pathname: '/' + this.state.survey_id + '/' + this.state.participation
                })
            )
            .then(() => window.location.reload());
    }

    changeHandler = event => {

        const name = event.target.name;
        const value = event.target.value;

        this.setState({
            formControls: {
                ...this.state.formControls,
                [name]: {...this.state.formControls[name], value}
            }
        });

    };

    getButtonState() {
        return (!(this.state.formControls.email.value && this.state.formControls.first_name.value && this.state.formControls.last_name.value));
    }


    render() {

        return (
            <div className="col-md-8 offset-md-2">
                <div className="card">
                    <div className="card-header">
                        <h1>Registration</h1>
                    </div>

                    <form className="registration-form form-group" onSubmit={this.handleSubmit}>
                        <label>
                            E-Mail:
                            <input type="email" name="email" value={this.state.formControls.email.value}
                                   onChange={this.changeHandler}/>
                        </label>
                        <label>
                            First Name:
                            <input type="text" name="first_name" value={this.state.formControls.first_name.value}
                                   onChange={this.changeHandler}/>
                        </label>
                        <label>
                            Last Name:
                            <input type="text" name="last_name" value={this.state.formControls.last_name.value}
                                   onChange={this.changeHandler}/>
                        </label>
                        <button type="submit" disabled={this.getButtonState()}
                                className="btn button-primary button-wide submit">Register
                        </button>

                    </form>
                </div>
                {/*surveyid*/}
                <div className={"final-space"}/>
            </div>
        );
    }
}

export default Registration;
