import React from 'react';
import {API_URL} from "../../constants/urls";

class Endscreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            survey_id: this.props.survey_id,
            participation_id: this.props.participation_id,
            question_id: this.props.data.step_number,
            title: this.props.data.title,
            topic_description: this.props.data.text

        };
        this.onClickFunction(this.state);
    };

    onClickFunction(state) {
        fetch(API_URL + 'answers', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "participation": state.participation_id,
                "question_id": state.question_id,
                "survey": state.survey_id,
                "answer": {
                    "text": "read"
                }
            })
        });
    }

    render() {
        return (
            <div className={"col-md-8 offset-md-2"}>
                <div className={"card"}>
                    <div className="card-header">
                        <h1 dangerouslySetInnerHTML={{__html: this.state.title}}/>
                    </div>
                    <div>
                        <p dangerouslySetInnerHTML={{__html: this.state.topic_description}}/>
                    </div>
                </div>
                <div className={"final-space"}/>
            </div>

        );
    }
}

export default Endscreen;
