import React, { Component } from 'react';

class Home extends Component {
  render() {

    return (
      <div>
        <h1>Welcome to LOTA</h1>

      </div>
    );
  }
}

export default Home;