import React from 'react';
import Switch from "react-switch";
import {API_URL} from "../../constants/urls";


class Consent extends React.Component {

    constructor(props) {
        super(props);
        this.handleConsentChange = this.handleConsentChange.bind(this);
        this.handlePhaseChange = this.handlePhaseChange.bind(this);
        this.state = {
            survey_id: this.props.survey_id,
            participation_id: this.props.participation_id,
            question_id: this.props.data.step_number,
            title: this.props.data.title,
            text: this.props.data.text,
            consent_given: false,
            phase_2: false,

        };
    };

    onClickFunction(state) {
        fetch(API_URL + 'answers', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "participation": state.participation_id,
                "question_id": state.question_id,
                "survey": state.survey_id,
                "answer": {
                    "consent_given": state.consent_given,
                    "phase_2": state.phase_2,
                }
            })
        }).then(() => this.props.componentDidMount());

    }

    handleConsentChange(checked) {
        this.setState({consent_given: checked, phase_2: checked});
    }

    handlePhaseChange(checked) {
        this.setState({phase_2: checked});
    }


    getButtonState() {
        return !this.state.consent_given

    }


    render() {
        return (
            <div className={"col-md-8 offset-md-2"}>
                <div className={"card"}>
                    <div className="card-header">
                        <h1 dangerouslySetInnerHTML={{__html: this.state.title}}/>
                    </div>
                    <div>
                        <p dangerouslySetInnerHTML={{__html: this.state.text}}/>
                    </div>
                </div>

                <div className={"card"}>
                    <div className={"consent-form row"}>
                        <label className={"col-8"}> I agree that the data I entered into this questionnaire will be
                            processed as decribed above.
                        </label>
                        <div className={"col-2"}>
                            <Switch
                                onChange={this.handleConsentChange}
                                checked={this.state.consent_given}

                                onColor="#86d3ff"
                                onHandleColor="#2693e6"
                                handleDiameter={30}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={20}
                                width={48}
                                className="react-switch"
                                id="material-switch"

                            />
                        </div>
                    </div>

                    <div className={"consent-form row"}>
                        <label className={"col-8"}>I agree that my personal data is used for sending me further
                            invitations to this or other projects using the LOTA software for participatory technology
                            assessment.</label>
                        <div className={"col-2"}>
                            <Switch

                                disabled={this.state.consent_given === false}

                                onChange={this.handlePhaseChange}
                                checked={this.state.phase_2}

                                onColor="#86d3ff"
                                onHandleColor="#2693e6"
                                handleDiameter={30}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={20}
                                width={48}
                                className="react-switch"
                                id="material-switch"
                            />
                        </div>
                    </div>
                </div>


                <div className={"card"}>
                    <button type="submit" disabled={this.getButtonState()}
                            className="btn button-primary button-wide submit" onClick={() => {
                        this.onClickFunction(this.state); /*window.location.reload()*/
                    }}>Finish
                    </button>
                </div>
            </div>
        );
    }
}

export default Consent;
