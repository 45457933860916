import React from 'react';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {API_INFO, API_STEPS, API_URL} from "../../constants/urls";
import Popup from "reactjs-popup";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons'
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

const getItemsFromArray = (sourcearray = []) =>
    Array.from({length: sourcearray.length}, (v, k) => ({
        title: sourcearray[k].title,
        description: sourcearray[k].description,
        long_description: sourcearray[k].long_description,
        id: sourcearray[k].id,
        collection: sourcearray[k].collection
    }));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

function sort_by_key(array, key) {
    return array.sort(function (a, b) {
        let x = a[key];
        let y = b[key];
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
}

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;
    return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'grey',

    // styles we need to apply on draggables
    ...draggableStyle
});

const getItemClass = (isDragging, draggableStyle) => ({
    class: isDragging ? "draggable-card dragging-card" : "draggable-card"
});

const getListStyle = (isDraggingOver, side) => ({
    background: isDraggingOver && side === 'right' ? 'white' : 'white',
    padding: grid,
    minHeight: 700
});


let arr_source = [];
let other_goal_comment = "";

class Sorting extends React.Component {

    constructor(props) {
        super(props);

        arr_source = []; // clear the previous set
        Object.keys(this.props.data.has_titles).forEach((key) => {
            arr_source.push(this.props.data.has_titles[key]);
        });

        arr_source = sort_by_key(arr_source, 'id');

        this.state = {
            survey_id: this.props.survey_id,
            participation_id: this.props.participation_id,
            question_id: this.props.data.step_number,
            component_title: this.props.data.title,
            topic_description: this.props.data.description,
            left_column: this.props.data.headline_choosen,
            right_column: this.props.data.headline_unchoosen,
            items: getItemsFromArray(arr_source),
            selected: getItemsFromArray(),
            min_goals: 2,
            max_goals: 4,
            backstep_show: false,
            backstep_title: '',
            backstep_description: '',
        };

        fetch(API_INFO)
            .then(response => response.json())
            .then(data => {
                this.setState({
                    backstep_show: data.backstep_show,
                    backstep_description: data.backstep_description,
                    backstep_title: data.backstep_title,
                });
            });

        fetch(API_STEPS)
            .then(response => response.json())
            .then(data => this.setState({max_goals: data.nr_targets}));
    };

    /**
     * A semi-generic way to handle multiple lists. Matches
     * the IDs of the droppable container to the names of the
     * source arrays stored in the state.
     */
    id2List = {
        droppable: 'items',
        droppable2: 'selected'
    };

    getList = title => this.state[this.id2List[title]];

    onDragEnd = result => {
        const {source, destination} = result;

        // dropped outside the list
        if (!destination) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            const items = reorder(
                this.getList(source.droppableId),
                source.index,
                destination.index
            );

            let state = {items};

            if (source.droppableId === 'droppable2') {
                state = {selected: items};
            }

            this.setState(state);
        } else {
            const result = move(
                this.getList(source.droppableId),
                this.getList(destination.droppableId),
                source,
                destination
            );

            this.setState({
                items: result.droppable,
                selected: result.droppable2
            });
        }

    };


    componentDidMount() {

        this.setState({
            isMobile: window.innerWidth < 768
        });

        window.addEventListener('resize', () => {
            this.setState({
                isMobile: window.innerWidth < 768
            });
        }, false);
    }

    onClickFunction(state) {
        fetch(API_URL + 'answers', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "participation": state.participation_id,
                "question_id": state.question_id,
                "survey": state.survey_id,
                "answer": state.selected,
                "other_goal": other_goal_comment
            })
        })
            .then(() => this.props.componentDidMount());
    }

    onDeleteFunction(state) {
        fetch(API_URL + 'delete-answer', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "participation": state.participation_id,
                "question_id": state.question_id,
                "survey": state.survey_id,
            })
        }).then(() => this.props.componentDidMount());

    }

    handleChange(event) {
        other_goal_comment = event.target.value;
    }

    getButtonState() {
        return this.state.selected.length < this.state.min_goals || this.state.selected.length > this.state.max_goals;
    }

    isButtonWarningHidden() {
        return !(this.state.isMobile && this.getButtonState())
    }


    // Normally you would want to split things out into separate components.
    // But in this example everything is just done in one place for simplicity
    render() {

        const left_card_col = this.state.isMobile ? 'col-7 column-left mobile' : 'col-6 column-left';
        const right_card_col = this.state.isMobile ? 'col-5 column-right mobile' : 'col-6 column-right';

        return (
            <div className={"col-md-8 offset-md-2"}>
                <div className={"card"}>
                    <div className="card-header">
                        <h1 dangerouslySetInnerHTML={{__html: this.state.component_title}}/>
                    </div>
                    <div>
                        <p dangerouslySetInnerHTML={{__html: this.state.topic_description}}/>
                    </div>
                </div>

                <DragDropContext onDragEnd={this.onDragEnd}>
                    <div className={"row drag-drop-container"}>

                        <div className={left_card_col}>

                            <h5>{this.state.left_column}</h5>
                            <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                    <div className={"card card-side left-card"}
                                         ref={provided.innerRef}
                                         style={getListStyle(snapshot.isDraggingOver, 'left')}>
                                        {this.state.items.map((item, index) => (
                                            <Draggable
                                                key={item.title}
                                                draggableId={item.title}
                                                index={index}>
                                                {(provided, snapshot) => (
                                                    <div className={getItemClass(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style).class}
                                                         ref={provided.innerRef}
                                                         {...provided.draggableProps}
                                                         {...provided.dragHandleProps}
                                                         style={getItemStyle(
                                                             snapshot.isDragging,
                                                             provided.draggableProps.style
                                                         )}>
                                                        <div>
                                                            {item.long_description !== null ?
                                                                <Popup trigger={
                                                                    <button
                                                                        className={"modal-trigger glyphicon glyphicon-info-sign"}>
                                                                        <FontAwesomeIcon icon={faInfoCircle}/>
                                                                    </button>} modal>

                                                                    {close => (
                                                                        <div className={"modal"}>
                                                                            <div className={"modal-header-bar"}>
                                                                                <button className="close"
                                                                                        onClick={close}>
                                                                                    &times;
                                                                                </button>
                                                                                <h2 className={"modal-title"}>{item.title}</h2>
                                                                            </div>
                                                                            <div className={"modal-content"}>
                                                                                <div className={"long_description"}
                                                                                     dangerouslySetInnerHTML={{__html: item.long_description}}/>
                                                                            </div>
                                                                        </div>

                                                                    )}
                                                                </Popup>
                                                                : ''}
                                                            <h4 className={"modal-title"}>{item.title}</h4>
                                                            {item.title !== "Other" ?
                                                                (<p className={"nonselect"}>{item.description}</p>)
                                                                :
                                                                (<label className={"other-goal-comment"}>
                                                                    <input type="text" placeholder={item.description}
                                                                           onChange={this.handleChange}
                                                                           onKeyUp={this.handleChange}/>
                                                                </label>)
                                                            }

                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>

                        </div>

                        <div className={right_card_col}>

                            <h5>{this.state.right_column}</h5>
                            <Droppable droppableId="droppable2">
                                {(provided, snapshot) => (
                                    <div className={"card card-side right-card"}
                                         ref={provided.innerRef}
                                         style={getListStyle(snapshot.isDraggingOver, 'right')}>
                                        {this.state.selected.map((item, index) => (
                                            <Draggable
                                                key={item.title}
                                                draggableId={item.title}
                                                index={index}>
                                                {(provided, snapshot) => (
                                                    <div className={getItemClass(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style).class}
                                                         ref={provided.innerRef}
                                                         {...provided.draggableProps}
                                                         {...provided.dragHandleProps}
                                                         style={getItemStyle(
                                                             snapshot.isDragging,
                                                             provided.draggableProps.style
                                                         )}>
                                                        <div>
                                                            {(item.title === "Other" && this.state.isMobile) ?
                                                                (<h4>{other_goal_comment}</h4>)
                                                                :
                                                                (<h4>{item.title}</h4>)
                                                            }

                                                            {item.title !== "Other" ?
                                                                (<p className={"item-content"}>{item.description}</p>)
                                                                :
                                                                (<p className={"item-content"}>{other_goal_comment}</p>)
                                                            }

                                                        </div>


                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>

                        </div>

                    </div>

                </DragDropContext>
                <div className={`${this.state.backstep_show ? 'button-card' : ''} card`}>
                    {this.state.backstep_show ?
                        <Popup trigger={
                            <button type="submit" className="button-faded col-lg-3 btn button-primary submit button-wide">
                                Back
                            </button>} modal>

                            {close => (
                                <div className={"modal"}>
                                    <div className={"modal-header-bar"}>
                                        <button className="close" onClick={close}>
                                            &times;
                                        </button>
                                        <h2 className={"modal-title"}
                                            dangerouslySetInnerHTML={{__html: this.state.backstep_title}}/>
                                    </div>
                                    <div className={"modal-content"}>
                                        <div dangerouslySetInnerHTML={{__html: this.state.backstep_description}}/>
                                    </div>
                                    <button type="submit" className="btn button-primary submit button-wide"
                                            onClick={() => {
                                                this.onDeleteFunction(this.state);
                                            }}>
                                        Confirm
                                    </button>
                                </div>
                            )}
                        </Popup> : ''
                    }
                    <label className={"button-warning-label"} hidden={this.isButtonWarningHidden()}>Amount of selected
                        goals is not correct</label>
                    <OverlayTrigger
                        trigger={['click', 'hover', 'focus']}
                        overlay={this.getButtonState() ?
                            <Tooltip id="tooltip-disabled">Amount of selected goals is not correct</Tooltip> : <div/>}>
                            <span className={`${this.state.backstep_show ? 'col-lg-8 px-0' : ''} d-inline-block `}>
                                <button type="submit"
                                        disabled={this.getButtonState()}
                                        className="btn button-primary button-wide submit "
                                        onClick={() => {
                                            this.onClickFunction(this.state)
                                        }}>Next</button>
                            </span>

                    </OverlayTrigger>


                </div>
                <div className={"final-space"}/>

            </div>

        );
    }
}

export default Sorting;
