import React from 'react';

import Ranking from "../Ranking/Ranking";
import Sorting from "../Sorting/Sorting";
import Comments from "../Comments/Comments";
import Unified from "../Unified/Unified";
import Introduction from "../Introduction/Introduction";
import Endscreen from "../Endscreen/Endscreen";
import Registration from "../Registration/Registration";
import ImpactMatrix from "../ImpactMatrix/ImpactMatrix";
import Consent from "../Consent/Consent";
import MatrixQuestion from "../MatrixQuestion/MatrixQuestion";

class ComponentSwitcher extends React.Component {
    constructor(props) {
        super(props);
        //this.componentDidMount = this.componentDidMount.bind(this);
        this.state = {
            survey_id: this.props.survey_id,
            participation_id: this.props.participation_id,
            data: this.props.data
        };
    };

    componentsRouting() {

        switch (this.state.data.component_type) {

            case "text":
                return <Introduction data={this.state.data}
                                     survey_id={this.state.survey_id}
                                     participation_id={this.state.participation_id}
                                     componentDidMount={this.props.componentDidMount}/>;

            case "sorting":
                return <Sorting data={this.state.data}
                                survey_id={this.state.survey_id}
                                participation_id={this.state.participation_id}
                                componentDidMount={this.props.componentDidMount}/>;

            case "ranking":
                return <Ranking data={this.state.data}
                                survey_id={this.state.survey_id}
                                participation_id={this.state.participation_id}
                                componentDidMount={this.props.componentDidMount}/>;

            case "matrix":
                return <MatrixQuestion data={this.state.data}
                                       survey_id={this.state.survey_id}
                                       participation_id={this.state.participation_id}
                                       componentDidMount={this.props.componentDidMount}/>;

            case "comment":
                return <Comments data={this.state.data}
                                 survey_id={this.state.survey_id}
                                 participation_id={this.state.participation_id}
                                 componentDidMount={this.props.componentDidMount}/>;

            case "end":
                return <Endscreen data={this.state.data}
                                  survey_id={this.state.survey_id}
                                  participation_id={this.state.participation_id}
                                  componentDidMount={this.props.componentDidMount}/>;

            case "impact":
                return <ImpactMatrix data={this.state.data}
                                     survey_id={this.state.survey_id}
                                     participation_id={this.state.participation_id}
                                     componentDidMount={this.props.componentDidMount}/>;

            case "consent":
                return <Consent data={this.state.data}
                                survey_id={this.state.survey_id}
                                participation_id={this.state.participation_id}
                                componentDidMount={this.props.componentDidMount}/>;
            case "unified":
                return <Unified data={this.state.data}
                                survey_id={this.state.survey_id}
                                participation_id={this.state.participation_id}
                                componentDidMount={this.props.componentDidMount}/>;


            //case "":                return <SurveyClosed  />;
            //case "":                return <SurveyNotStarted />;

            default:
                return <Registration survey_id={this.state.survey_id}/>;
        }
    }

    render() {

        return (
            <div>
                {this.componentsRouting()}
            </div>
        );
    }
}

export default ComponentSwitcher;
