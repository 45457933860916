import React from 'react';

class NotRunning extends React.Component {

    render() {
        return (
            <div className={"col-md-8 offset-md-2"}>
                <div className={"card"}>
                    <div className="card-header">
                        <h1>Survey is not running</h1>
                    </div>
                    <div>
                        <p>The survey has not been started or has been ended</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default NotRunning;
