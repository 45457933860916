import React, {Component} from "react";
import {ProgressBar} from './components';
import "./ui-design.scss";
import "./stylesheets/bootstrap.min.css"

import ComponentSwitcher from "./components/ComponentSwitcher/ComponentSwitcher";
import Registration from "./components/Registration/Registration";
import {API_URL} from "./constants/urls";
import NotRunning from "./components/NotRunning/NotRunning";
import NotFound from "./components/NotFound/NotFound";
import ParticipationNotFound from "./components/ParticipationNotFound/ParticipationNotFound";
import Completed from "./components/Completed/Completed";
import Spinner from 'react-bootstrap/Spinner'

const API = API_URL + 'survey/' + window.location.pathname.split('/')[1] + '/next_question/' + window.location.pathname.split('/')[2];

const survey_id = window.location.pathname.split('/')[1];
const participation_id = window.location.pathname.split('/')[2];

class App extends Component {
    constructor() {
        super();
        this.componentDidMount = this.componentDidMount.bind(this);
        this.state = {
            survey_id: survey_id,
            participation_id: participation_id,
            data: [],
            isLoading: false,
            error: null,
            steps: 0
        };
    }

    componentDidMount() {
        this.setState({isLoading: true});

        fetch(API)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    return response.text();
                }
            })
            .then(data => this.setState({data: data, isLoading: false}))
            .then(() => this.forceUpdate())
            .catch(error => this.setState({error: error.message, isLoading: false}));
    }


    render() {

        if (this.state.data === "not found") {
            return (
                <div>
                    <div className={"progressbar_placeholder"}/>
                    <NotFound/>
                </div>
            );
        }

        if (this.state.data === "participation not found") {
            return (
                <div>
                    <div className={"progressbar_placeholder"}/>
                    <ParticipationNotFound/>
                </div>
            );
        }

        if (this.state.data === "completed") {
            return (
                <div>
                    <div className={"progressbar_placeholder"}/>
                    <Completed/>
                </div>
            );
        }

        if (this.state.data === "not running") {
            return (
                <div>
                    <div className={"progressbar_placeholder"}/>
                    <NotRunning/>
                </div>
            );
        }

        switch (this.state.participation_id) {
            case undefined:
                return (
                    <div className={"main-panel"}>
                        <div className={"progressbar_placeholder"}/>
                        <Registration survey_id={this.state.survey_id}/>
                    </div>);

            case '':
                return (
                    <div className={"main-panel"}>
                        <div className={"progressbar_placeholder"}/>
                        <Registration survey_id={this.state.survey_id}/>
                    </div>);

            default: {
                const isLoading = this.state.isLoading;

                if (isLoading) {
                    return (
                        <Spinner animation="border"/>);
                }

                return (
                    <div className="main-panel">
                        <ProgressBar active_step={this.state.data.step_number - 1}
                                     level={this.state.data.level}

                        />
                        <ComponentSwitcher data={this.state.data}
                                           survey_id={this.state.survey_id}
                                           participation_id={this.state.participation_id}
                                           componentDidMount={this.componentDidMount}
                        />

                    </div>
                );

            }
        }

    };
}

export default App;


