import React from 'react';
import {API_URL, API_INFO, BASE_URL} from "../../constants/urls";
import Popup from "reactjs-popup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";

class Comments extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            survey_id: this.props.survey_id,
            participation_id: this.props.participation_id,
            question_id: this.props.data.step_number,
            title: this.props.data.title,
            topic_description: this.props.data.text,
            value: "",
            show_info: this.props.data.show_info,
            info_description: '',
            info_title: ''
        };

        fetch(API_INFO)
            .then(response => response.json())
            .then(data => {
                this.setState({info_title: data.technology, info_description: data.description});
            });

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    };


    handleChange(event) {
        this.setState({value: event.target.value});
    }

    handleSubmit(event) {
        fetch(API_URL + 'answers', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "participation": this.state.participation_id,
                "question_id": this.state.question_id,
                "survey": this.state.survey_id,
                "answer": this.state.value,
            })
        })
            .then(() => this.props.componentDidMount());
        event.preventDefault();
    }


    render() {
        return (
            <div className={"col-md-8 offset-md-2"}>
                <div className={"card"}>
                    <div className="card-header">
                        <h1 dangerouslySetInnerHTML={{__html: this.state.title}}/>
                    </div>
                    <div>
                        <p className={"description"} dangerouslySetInnerHTML={{__html: this.state.topic_description.replaceAll('/static/survey/', BASE_URL + '/static/survey/')}}/>
                        {this.state.show_info ?
                            <Popup trigger={
                                <button className={"modal-trigger glyphicon glyphicon-info-sign"}>
                                    {this.state.info_title} <FontAwesomeIcon icon={faInfoCircle}/>
                                </button>} modal>

                                {close => (
                                    <div className={"modal"}>
                                        <div className={"modal-header-bar"}>
                                            <button className="close" onClick={close}>
                                                &times;
                                            </button>
                                            <h2 className={"modal-title"}
                                                dangerouslySetInnerHTML={{__html: this.state.info_title}}/>
                                        </div>
                                        <div className={"modal-content"}>
                                            <div dangerouslySetInnerHTML={{
                                                __html: this.state.info_description
                                                    .replaceAll('/static/survey/', BASE_URL + '/static/survey/')
                                            }}/>
                                        </div>
                                    </div>
                                )}
                            </Popup> : ''
                        }
                    </div>
                    <form onSubmit={this.handleSubmit}>
                        <label>
                            <textarea value={this.state.feedback} onChange={this.handleChange}/>
                        </label>
                        <button type="submit" className="btn button-primary button-wide submit">Send
                        </button>
                    </form>
                </div>
                <div className={"final-space"}/>
            </div>
        );
    }
}

export default Comments;
