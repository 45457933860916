import React from 'react';

class SurveyNotStarted extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.data.topic.title,
            topic_description: this.props.data.topic.topic_description,
        };
    };

    render() {
        return (
            <div className={"col-md-8 offset-md-2"}>
                <div className={"card"}>
                    <div className="card-header">
                        <h1>Survey not started yet</h1>
                    </div>
                    <div>
                        <p>Please try it later again</p>
                    </div>
                </div>
                <div className={"final-space"}/>
            </div>
        );
    }
}

export default SurveyNotStarted;
