import React from 'react';
import {API_URL, API_INFO, BASE_URL} from "../../constants/urls";
import Popup from "reactjs-popup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";

class Unified extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            survey_id: this.props.survey_id,
            participation_id: this.props.participation_id,
            question_id: this.props.data.step_number,
            unified_goals: this.props.data.unified_goals,
            title: this.props.data.title,
            topic_description: this.props.data.text,
            show_info: this.props.data.show_info,
            info_description: '',
            info_title: ''
        }

        fetch(API_INFO)
            .then(response => response.json())
            .then(data => {
                this.setState({info_title: data.technology, info_description: data.description});
            });
    };

    onClickFunction(state) {
        fetch(API_URL + 'answers', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "participation": state.participation_id,
                "question_id": state.question_id,
                "survey": state.survey_id,
                "answer": {
                    "unified": "read"
                }
            })
        }).then(() => this.props.componentDidMount());

    }


    render() {

        return (
            <div className={"col-md-8 offset-md-2"}>
                <div className={"card"}>
                    <div className="card-header">
                        <h1 dangerouslySetInnerHTML={{__html: this.state.title}}/>
                    </div>
                    <div>
                        <p dangerouslySetInnerHTML={{__html: this.state.topic_description}}/><br/>
                        <h4>List of unified goals selected by your moderator: </h4>
                        <p>
                            <ul>
                                {this.state.unified_goals.map((value, index) => {
                                    return <li key={index}>{value}</li>
                                })}
                            </ul>
                        </p>
                        {this.state.show_info ?
                            <Popup trigger={
                                <button className={"modal-trigger glyphicon glyphicon-info-sign"}>
                                    {this.state.info_title} <FontAwesomeIcon icon={faInfoCircle}/>
                                </button>} modal>

                                {close => (
                                    <div className={"modal"}>
                                        <div className={"modal-header-bar"}>
                                            <button className="close" onClick={close}>
                                                &times;
                                            </button>
                                            <h2 className={"modal-title"}
                                                dangerouslySetInnerHTML={{__html: this.state.info_title}}/>
                                        </div>
                                        <div className={"modal-content"}>
                                            <div dangerouslySetInnerHTML={{
                                                __html: this.state.info_description
                                                    .replaceAll('/static/survey/', BASE_URL + '/static/survey/')
                                            }}/>
                                        </div>
                                    </div>
                                )}
                            </Popup> : ''
                        }
                    </div>
                </div>
                <div className={"card"}>
                    <button type="submit" className="btn button-primary button-wide submit"
                            onClick={() => {
                                this.onClickFunction(this.state); /*window.location.reload()*/
                            }}>Next
                    </button>
                </div>
                <div className={"final-space"}/>

            </div>
        );
    }
}

export default Unified;
