import NavBar from './NavBar/NavBar';
import ProgressBar from './ProgressBar/ProgressBar';
import Footer from './Footer/Footer';

import Home from './Home/Home';
import Contacts from './Contacts/Contacts';
import NotFound from './NotFound/NotFound';
import NotRunning from './NotRunning/NotRunning';
import SurveyClosed from './SurveyStatus/SurveyClosed';
import SurveyNotStarted from "./SurveyStatus/SurveyNotStarted";

import ComponentSwitcher from './ComponentSwitcher/ComponentSwitcher';

import Registration from './Registration/Registration';
import Introduction from './Introduction/Introduction';
import Sorting from './Sorting/Sorting';
import Ranking from './Ranking/Ranking';
import MatrixQuestion from './MatrixQuestion/MatrixQuestion'
import Comments from './Comments/Comments';
import Endscreen from './Endscreen/Endscreen';
import ImpactMatrix from './ImpactMatrix/ImpactMatrix';

import Consent from './Consent/Consent';


export {
    NavBar,
    SurveyClosed,
    SurveyNotStarted,
    ProgressBar,
    Footer,
    Introduction,
    Contacts,
    Home,
    NotFound,
    Registration,
    Ranking,
    MatrixQuestion,
    Sorting,
    Comments,
    Endscreen,
    ComponentSwitcher,
    ImpactMatrix,
    Consent,
    NotRunning
};