import React, { Component } from 'react';
import './Footer.css';

class Footer extends Component {
    render() {
        return (
            <div className="Footer">
                <button>Next -></button>
            </div>
        );
    }
}

export default Footer;