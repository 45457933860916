import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class NavBar extends Component {
    render() {
        return (
            <ul className="NavList">
                <li>
                    <p>Test Links:</p>
                </li>
                <li>
                    <Link to="/registration">Registration</Link>
                </li>
                <li>
                    <Link to="/introduction">Introduction</Link>
                </li>
                <li>
                    <Link to="/ranking">Ranking</Link>
                </li>
                <li>
                    <Link to="/matrix">Matrix</Link>
                </li>
                <li>
                    <Link to="/comments">Comments</Link>
                </li>
                <li>
                    <Link to="/endscreen">Endscreen</Link>
                </li>
            </ul>
        );
    }
}

export default NavBar;