import React, {Component} from 'react'
import {ButtonGroup} from 'react-bootstrap'
import {API_INFO, API_URL, BASE_URL} from "../../constants/urls";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from 'react-bootstrap/Tooltip'
import Popup from "reactjs-popup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";


const getItemClass = (answer, index) => ({
    class: (answer === index) ? "button-multi button-multi-active" : "button-multi"
});

function getIndexOf(arr, value, props) {
    for (let i = 0; i < arr.length; i++) {
        if (arr[i][props] === value) {
            return i;
        }
    }
    return -1; // if value was not found
}

function sort_by_key(array, key) {
    return array.sort(function (a, b) {
        let x = a[key];
        let y = b[key];
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
}

let arr_selected_goals = [];
let arr_ranking = [];

let all_question_ids = [];
let unselected_question_ids = [];


function set_unselected_style() {

    all_question_ids.map(id =>
        document.getElementById(id).classList.remove("alert-card")
    );

    unselected_question_ids.map(id =>
        document.getElementById(id).classList.add("alert-card")
    );

}


class Ranking extends Component {

    constructor(props) {
        super(props);
        this.updateRankingState = this.updateRankingState.bind(this);
        this.state = {
            page_title: this.props.data.title,
            page_description: this.props.data.description,
            survey_id: this.props.survey_id,
            participation_id: this.props.participation_id,
            question_id: this.props.data.step_number,
            sorted_selected_goals: [],
            sorted_has_questions: [],
            data: this.props.data,
            created_data: [],
            show_info: this.props.data.show_info,
            info_description: '',
            info_title: '',
            backstep_title: '',
            backstep_description: '',
        };

        fetch(API_INFO)
            .then(response => response.json())
            .then(data => {
                this.setState({
                    info_title: data.technology,
                    info_description: data.description,
                    backstep_show: data.backstep_show,
                    backstep_description: data.backstep_description,
                    backstep_title: data.backstep_title,
                });
            });

        sort_by_key(this.state.data.selected_goals, 'rank');

        if (this.state.data.former_questions) {
            this.state.data.has_questions = this.state.data.former_questions
        }
        sort_by_key(this.state.data.has_questions, 'id');

        arr_selected_goals = [];
        Object.keys(this.state.data.selected_goals).forEach((goal) => {
            let arr_has_questions = {
                id: this.state.data.selected_goals[goal].id,
                rank: this.state.data.selected_goals[goal].rank,
                title: this.state.data.selected_goals[goal].title,
                description: this.state.data.selected_goals[goal].description,
                questions: [],
                //goal_comment:""
            };
            Object.keys(this.state.data.has_questions).forEach((key) => {
                let temp = {
                    id: this.state.data.has_questions[key].id,
                    goal_id: goal,
                    question: this.state.data.has_questions[key].question,
                    collection: this.state.data.has_questions[key].collection,
                    answer_style: this.state.data.answer_style.split(', '),
                    answer: null,
                    has_comment: this.state.data.has_questions[key].has_comment,
                    comment_title: this.state.data.has_questions[key].comment_title,
                    question_comment: "",
                    has_input: this.state.data.has_questions[key].has_input,
                    input_title: this.state.data.has_questions[key].input_title,
                    input_type: this.state.data.has_questions[key].input_type,
                    input_user: "",
                };

                arr_has_questions.questions.push(temp);
            });
            arr_selected_goals.push(arr_has_questions);
        });

        this.state.created_data = arr_selected_goals;

        arr_ranking = []; // clear the previous set
        Object.keys(this.state.created_data).forEach((key) => {
            arr_ranking.push(this.state.created_data[key]);
        });

    };

    componentDidMount() {

        this.setState({
            isMobile: window.innerWidth < 768
        });

        window.addEventListener('resize', () => {
            this.setState({
                isMobile: window.innerWidth < 768
            });
        }, false);
    }

    updateRankingState(id, questions, goal_comment) {

        let index = getIndexOf(arr_selected_goals, id, "id");
        this.state.created_data[index].questions = questions;
        this.state.created_data[index].goal_comment = goal_comment;
        this.forceUpdate();
    }

    onClickFunction(state) {

        set_unselected_style();

        if (unselected_question_ids.length === 0) {
            fetch(API_URL + 'answers', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "participation": state.participation_id,
                    "question_id": state.question_id,
                    "survey": state.survey_id,
                    "answer": state.created_data,
                })
            })
                .then(() => this.props.componentDidMount());
        }
    }

    onDeleteFunction(state) {
        fetch(API_URL + 'delete-answer', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "participation": state.participation_id,
                "question_id": state.question_id,
                "survey": state.survey_id,
            })
        }).then(() => this.props.componentDidMount());

    }

    getButtonState() {

        let all_answers_given = true;

        loop1:
            for (let i in this.state.created_data) {
                for (let j in this.state.created_data[i].questions) {
                    if (this.state.created_data[i].questions[j].answer == null) {
                        all_answers_given = false;
                        break loop1;
                    }
                }
            }

        return !all_answers_given
    }

    isButtonWarningHidden() {
        return !(this.state.isMobile && this.getButtonState())
    }

    getSubmitButtonClass() {
        if (unselected_question_ids.length === 0) {
            return "btn button-primary button-wide submit"
        } else {
            return "btn button-primary button-wide submit disabled"
        }
    }

    render() {


        return (
            <div className={"col-md-8 offset-md-2"}>

                <div className={"card"}>
                    <div className="card-header">
                        <h1 dangerouslySetInnerHTML={{__html: this.state.page_title}}/>
                    </div>
                    <div>
                        <p dangerouslySetInnerHTML={{__html: this.state.page_description}}/>
                        {this.state.show_info ?
                            <Popup trigger={
                                <button className={"modal-trigger glyphicon glyphicon-info-sign"}>
                                    {this.state.info_title} <FontAwesomeIcon icon={faInfoCircle}/>
                                </button>} modal>

                                {close => (
                                    <div className={"modal"}>
                                        <div className={"modal-header-bar"}>
                                            <button className="close" onClick={close}>
                                                &times;
                                            </button>
                                            <h2 className={"modal-title"}
                                                dangerouslySetInnerHTML={{__html: this.state.info_title}}/>
                                        </div>
                                        <div className={"modal-content"}>
                                            <div dangerouslySetInnerHTML={{
                                                __html: this.state.info_description
                                                    .replaceAll('/static/survey/', BASE_URL + '/static/survey/')
                                            }}/>
                                        </div>
                                    </div>
                                )}
                            </Popup> : ''
                        }
                    </div>
                </div>

                <ul className="card-list">
                    {arr_ranking.map(item => <Set key={item.id}
                                                  id={item.id}
                                                  goal_id={item.goal_id}
                                                  rank={item.rank}
                                                  title={item.title}
                                                  topic_description={item.description}
                                                  questions={item.questions}
                                                  goal_comment={item.goal_comment}
                                                  updateRankingState={this.updateRankingState}
                    />)}

                </ul>
                <div className={`${this.state.backstep_show ? 'button-card' : ''} card`}>
                    {this.state.backstep_show ?
                        <Popup trigger={
                            <button type="submit" className="button-faded col-lg-3 btn button-primary submit button-wide">
                                Back
                            </button>} modal>

                            {close => (
                                <div className={"modal"}>
                                    <div className={"modal-header-bar"}>
                                        <button className="close" onClick={close}>
                                            &times;
                                        </button>
                                        <h2 className={"modal-title"}
                                            dangerouslySetInnerHTML={{__html: this.state.backstep_title}}/>
                                    </div>
                                    <div className={"modal-content"}>
                                        <div dangerouslySetInnerHTML={{__html: this.state.backstep_description}}/>
                                    </div>
                                    <button type="submit" className="btn button-primary submit button-wide"
                                            onClick={() => {
                                                this.onDeleteFunction(this.state);
                                            }}>
                                        Confirm
                                    </button>
                                </div>
                            )}
                        </Popup> : ''
                    }

                    <label className={"button-warning-label"} hidden={this.isButtonWarningHidden()}>Some answers are
                        missing</label>

                    <OverlayTrigger
                        trigger={['click', 'hover', 'focus']}
                        overlay={this.getButtonState() ?
                            <Tooltip id="tooltip-disabled">Some answers are missing</Tooltip> : <div/>}>
                            <span className={`${this.state.backstep_show ? 'col-lg-8 px-0' : ''} d-inline-block `}>
                                <button type="submit"
                                        className={this.getSubmitButtonClass()}
                                        onClick={() => {
                                            this.onClickFunction(this.state)
                                        }}>Next</button>
                            </span>

                    </OverlayTrigger>

                </div>
                <div className={"final-space"}/>
            </div>


        );
    }
}

var arr_set = [];

class Set extends React.Component {

    constructor(props) {
        super(props);
        this.updateSetState = this.updateSetState.bind(this);
        this.state = {
            set: this.props.id,
            goal_id: this.props.goal_id,
            title: this.props.title,
            topic_description: this.props.topic_description,
            questions: this.props.questions,
            goal_comment: this.props.goal_comment,

        };
        this.handleChange = this.handleChange.bind(this);

        arr_set = []; // clear the previous set
        Object.keys(this.state.questions).forEach((key) => {
            arr_set.push(this.state.questions[key]);
        });

    };


    handleChange(event) {
        this.setState({goal_comment: event.target.value});
        this.props.updateRankingState(this.state.set, this.state.questions, this.state.goal_comment);
    }

    updateSetState(id, answer, comment, input_user) {
        let index = getIndexOf(arr_set, id, "id");
        this.state.questions[index].answer = answer;
        this.state.questions[index].question_comment = comment;
        this.state.questions[index].input_user = input_user;
        this.props.updateRankingState(this.state.set, this.state.questions, this.state.goal_comment, this.state.input_user);
    }

    render() {

        return (
            <div>
                <div className={"card ranking"}>
                    <div>
                        <div>
                            <h2 className={'ranking-title'}>{this.state.title}</h2>
                        </div>
                        <div>
                            <p>{this.state.topic_description}</p>
                        </div>
                    </div>
                    <ul className="card-list">
                        {arr_set.map(item => <Question key={item.question}
                                                       question={item.question}
                                                       id={item.id}
                                                       goal_id={item.goal_id}
                                                       answer_style={item.answer_style}
                            //answer_style = {this.answer_styles.answer_style} //quick fix
                                                       answer={item.answer}
                                                       comment_title={item.comment_title}
                                                       has_comment={item.has_comment}
                                                       question_comment={item.question_comment}
                                                       has_input={item.has_input}
                                                       input_title={item.input_title}
                                                       input_type={item.input_type}
                                                       input_user={item.input_user}
                                                       updateSetState={this.updateSetState}/>)}
                    </ul>
                </div>
            </div>
        )
    }
}

class Question extends React.Component {
    constructor(props) {
        super(props);
        this.set_answer = this.set_answer.bind(this);
        this.state = {
            id: this.props.id,
            goal_id: this.props.goal_id,
            question: this.props.question,
            answer_style: this.props.answer_style,
            answer: this.props.answer,
            comment_title: this.props.comment_title,
            has_comment: this.props.has_comment,
            question_comment: this.props.question_comment,
            has_input: this.props.has_input,
            input_title: this.props.input_title,
            input_type: this.props.input_type,
            input_user: this.props.input_user,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);


        all_question_ids.push(this.state.goal_id + "_" + this.state.id);
        unselected_question_ids.push(this.state.goal_id + "_" + this.state.id);
    }

    arrayRemove(arr, value) {
        return arr.filter(function (element) {
            return element !== value;
        })
    }

    set_answer(answer) {
        this.setState({answer: answer});
        this.props.updateSetState(this.state.id, answer);
        unselected_question_ids = this.arrayRemove(unselected_question_ids, this.state.goal_id + "_" + this.state.id);
    }

    handleChange(event) {
        this.setState({question_comment: event.target.value});
        this.props.updateSetState(this.state.id, this.state.answer, this.state.question_comment, this.state.input_user);

    }

    handleInputChange(event) {
        this.setState({input_user: event.target.value});
        this.props.updateSetState(this.state.id, this.state.answer, this.state.question_comment, this.state.input_user);
    }

    get_ranking_card_class() {
        return 'ranking-card'
    }

    render() {
        let comment;
        if (this.state.has_comment) {
            comment = <label>
                {this.state.comment_title}
                <input type="text" value={this.state.question_comment} onChange={this.handleChange}
                       onKeyUp={this.handleChange}/>
            </label>;
        }

        return (
            <div id={this.state.goal_id + "_" + this.state.id} ref={this.state.goal_id + "_" + this.state.id}
                 className={this.get_ranking_card_class()}>

                <p className={'ranking-question'}>{this.props.question}</p>

                <ButtonGroup className={'ranking-buttons'}>
                    <button className={getItemClass(this.state.answer, 1).class} onClick={(e) => {
                        this.set_answer(1);
                    }}>{this.props.answer_style[0]} </button>
                    <button className={getItemClass(this.state.answer, 2).class} onClick={(e) => {
                        this.set_answer(2);
                    }}>{this.props.answer_style[1]} </button>
                    <button className={getItemClass(this.state.answer, 3).class} onClick={(e) => {
                        this.set_answer(3);
                    }}>{this.props.answer_style[2]} </button>
                    <button className={getItemClass(this.state.answer, 4).class} onClick={(e) => {
                        this.set_answer(4);
                    }}>{this.props.answer_style[3]} </button>
                </ButtonGroup>
                {this.state.has_input === true && this.state.answer > 2 ?
                    <div>
                        <p style={{marginTop: "5px"}} className={'ranking-question'}>{this.state.input_title}</p>
                        <input style={{maxWidth: "600px", marginTop: "2px", marginBottom: "2px"}} type="text" value={this.state.input_user} onChange={this.handleInputChange}
                               onKeyUp={this.handleInputChange}/>
                    </div>
                    : '' }
                {comment}
                <div/>
            </div>
        )
    }
}

export default Ranking;

